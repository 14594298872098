import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import FormContainer from 'components/LoanForm/FormContainer';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { EMAIL_PATTERN, YourContactInputLabel } from 'components/LoanForm/YourContact/YourContact';
import FormNavigation from 'components/FormNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setYourContactData } from 'handlers/yourContact';
import { getYourContactData } from 'selectors/getYourContact';
import { YourContactVariable } from 'enums/LoanFormVariables';
import { FlowComponentType } from 'routes/types';
import { getNewInputValue } from 'utils/inputHelpers';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { sendEmailCode } from 'thunks';

import styles from './YourEmail.module.scss';

const YourEmail = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { phone_number: phoneNumber, email } = useSelector(getYourContactData);
  const [shouldContinue, setShouldContinue] = useState<boolean>(false);
  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email,
    },
  });

  const watcher = watch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = getNewInputValue(watcher[YourContactVariable.Email], event.target.value.trim());
    setValue(YourContactVariable.Email, newValue);
    trigger(YourContactVariable.Email);
  };

  const onBlur = () => {
    trigger(YourContactVariable.Email);
  };

  const handleContinue = async () => {
    dispatch(setYourContactData(watcher));
    setIsSendingCode(true);
    await dispatchWithUnwrap(sendEmailCode({ phoneNumber, email: watcher[YourContactVariable.Email]! }));
    setIsSendingCode(false);
    setShouldContinue(true);
  };

  useEffect(() => {
    register(YourContactVariable.Email, {
      required: getMessageForRequiredFields(YourContactInputLabel.Email),
      pattern: {
        message: getMessageForInvalidFields(YourContactInputLabel.Email),
        value: EMAIL_PATTERN,
      },
    });
  }, [register]);

  useEffect(() => {
    if (shouldContinue) {
      handleNext();
    }
  }, [shouldContinue]);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Access Your Account"
        subtitle="Return to your account in the future by providing your email."
      >
        <Input
          value={watcher[YourContactVariable.Email]}
          className={styles.formInput}
          onChange={onChange}
          onBlur={onBlur}
          label={YourContactInputLabel.Email}
          placeholder="youremail@mail.com"
          errorMessage={errors[YourContactVariable.Email]?.message}
          autoComplete="email"
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          autoFocus
          disabled={isSendingCode}
        />

        <Button className={styles.button} disabled={!isValid} onClick={handleContinue} isLoading={isSendingCode}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default YourEmail;
