export enum RoutePath {
  Offer = '/offer',
  YourGoal = '/your-goal',
  ExitSurvey = '/exit-survey',
  YourName = '/your-name',
  OutOfState = '/out-of-state',

  CreatingOffer = '/creating-offer',
  OfferAvailable = '/offer-available',
  ImportBalance = '/import-balance',
  HardOffer = '/hard-offer',
  PayOffPlan = '/pay-off-plan',
  PlanSent = '/plan-sent',

  CardInfo = '/card-info',

  ConfirmLoan = '/confirm-loan',
  DocuSignSignature = '/docu-sign-signature',
  SetupAccount = '/setup-account',
  VerificationSteps = '/verification-steps',
  VerifyingApplication = '/verifying-application',
  Alloy = '/alloy',
  RepaymentMethod = '/repayment-method',
  SetupDeduction = '/setup-deduction',
  SetupViaPayroll = '/setup-via-payroll',
  SetupViaHr = '/setup-via-hr',
  VerifyPayrollDeduction = '/verify-payroll-deduction',

  PreQualificationDecline = '/pre-qualification-decline',

  EmailNotificationAlert = '/email-alert',

  NextSteps = '/next-steps',

  OfferStatus = '/offer-status',

  Feedback = '/feedback',
  FundsSent = '/funds-sent',

  Error = '/error',

  // Verify SMS to relogin
  SmsLogin = '/smsLogin',

  // Financial Checkup
  YourPhoneNumber = '/your-phone-number',
  VerifyPhoneNumber = '/verify-phone-number',
  YourEmail = '/your-email',
  VerifyEmail = '/verify-email',
  YourIncome = '/your-income',
  YourSavings = '/your-savings',
  YourFinances = '/your-finances',
  MethodAuth = '/auth',
  Analyzing = '/analyzing',
  YourBirthDate = '/your-birth-date',
  YourAddress = '/your-address',
  YourSSN4 = '/your-short-ssn',

  CardApplied = '/card-applied',
  HowItWorks = '/how-it-works',

  // Card
  CardApplicationSuccessful = '/card-application-successful',
  ReferralProgram = '/referral-program',
  XSellLoader = '/xsell-loader',
  ErrorConsolidating = '/error-consolidating',
  ConnectPaycheck = '/connect-paycheck',
  ConnectPaycheckFeedback = '/connect-paycheck-feedback',

  // Not being used
  Location = '/location',
  LoanAmountRange = '/loan-amount',
  CreditScoreRange = '/credit-score',

  // Open DDA
  OpenDDADone = '/open-dda-done',

  // Missed Payments
  MissedPayment = '/missed-payment',
  MakeAPayment = '/make-a-payment',
  MissedPaymentReason = '/missed-payment-reason',
  Plaid = '/employment-verify',
  PlaidCheckingAccounts = '/checking-accounts',
  BankAccount = '/bank-account',
  Authorization = '/authorization',
  WaitingAuthorization = '/waiting-authorization',
  MakingPayment = '/making-payment',
  PaymentInitiated = '/payment-initiated',
  PaymentFailed = '/payment-failed',

  // Student Loan
  StudentLoanEmployerType = '/student-loan-employer-type',
  StudentLoanSaveRepaymentPlan = '/student-loan-save-repayment-plan',
  StudentLoanNonProfitStartDate = '/student-loan-non-profit-start-date',
  StudentLoanMohela = '/student-loan-mohela',
  StudentLoanDependentsQuantity = '/student-loan-dependents',
  StudentLoanNotEnrolled = '/student-loan-not-enrolled',
  StudentLoanPaymentSituations = '/student-loan-payment-situations',
  StudentLoanSavings = '/student-loan-savings',
  StudentLoanBookAppointment = '/student-loan-book-appointment',
  StudentLoanBookAdvisor = '/student-loan-book-advisor',
  StudentLoanNotEligible = '/student-loan-not-eligible',
  StudentLoanScheduleDetails = '/student-loan-schedule-details',
  StudentLoanApplyOptions = '/student-loan-apply-options',
  StudentLoanApplyOnYourOwn = '/student-loan-apply-on-your-own',
  StudentLoanApplyWithUs = '/student-loan-apply-with-us',
  StudentLoanApplySubmit = '/student-loan-apply-submit',
  StudentLoanApplyPaymentResult = '/student-loan-apply-result',
  StudentLoanApplyStepsIntro = '/student-loan-apply-steps-intro',
  StudentLoanApplySteps = '/student-loan-apply-steps',
  StudentLoanApplyStepsCompleted = '/student-loan-apply-steps-completed',
  StudentLoanCheckForgiveness = '/student-loan-check-forgiveness',
  StudentLoanComeBackWhenDue = '/student-loan-come-back-when-due',
  StudentLoanServiceAgreementDone = '/sl-service-agreement-done',

  // Debt Consolidation Waitlist
  DebtConsolidationSummary = '/debt-consolidation-summary',
  DebtConsolidationEarlyAccess = '/debt-consolidation-early-access',
  DebtConsolidationWaitlist = '/debt-consolidation-waitlist',
}
